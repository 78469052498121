import * as React from 'react';
import Blog from './Blog';
function HomePage(props) {
  return (
    <React.Fragment>
      <Blog />
    </React.Fragment>
  );
}
export default HomePage;
