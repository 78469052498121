import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import APP_HTTP from '../../APP_HTTP';

import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
import Watermark from '../WaterMark';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let paymentDataInitialState = []

const PrintDemandBill = (props)=> {
    let item_index = 0;
    let [paper_size,setPaperSize] = React.useState('A4');
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [no_of_print_page,setNumberOfPage] = React.useState([1]);
    let [paymentData,setPaymentData] = React.useState(paymentDataInitialState);
    
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            if(props.payment_data){
                let payment_data = props.payment_data;
                let number_of_copy = props.payment_data[0].number_of_copy;
                if(number_of_copy!==undefined){
                    number_of_copy = parseInt(number_of_copy);
                    let number_of_copy_arr = [];
                    for (let index = 0; index < number_of_copy; index++) {
                        number_of_copy_arr.push(index)
                    }
                    setNumberOfPage(number_of_copy_arr);
                }
                if(payment_data.is_db===undefined){
                    setPaymentData(payment_data);
                    setCurrentSequence(payment_data[0].payment_sequence);
                    setTimeout(() => {
                        handlePrintPayment();
                    }, 100);
                    
                }else{
                    let is_demand_bill = false;
                    if(props.is_demand_bill!==undefined){
                        is_demand_bill = props.is_demand_bill;
                    }
                    APP_HTTP.REQUEST('payment/get_payment_on_id',{id:payment_data.payment_id,is_demand_bill:is_demand_bill},true,true).then((response) => {
                        let resp = response.data.response;
                        setPaymentData([resp]);
                        setCurrentSequence(resp.payment_sequence);
                        setTimeout(() => {
                        handlePrintPayment();
                    }, 100);
                    });
                }
            }
            
        }
       
    },[props])
   
   const handlePrintPayment = ()=>{
    if(document.getElementById("paymentPrintBlock")){
        var divContents = document.getElementById("paymentPrintBlock").innerHTML; 
        
      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
        
        let page_settings='@page{size:"A4";margin:0}';
        if(props.payment_data.paper_size==='A5'){
            page_settings='@page{size: "A5"; width: 148mm;height: 210mm;margin: 0;}html {width: 148mm;height: 210mm;margin: 0;}body {width: 148mm;height: 210mm; margin: 0;}';
        }
          a.document.write('<style type="text/css"><style type="text/css">'+page_settings+'table{font-family:verdana, arial, sans-serif;font-size:16px;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:top;border:1px solid #333}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
            a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="paymentPrintBlock" style={{display:'none'}}>
        {(no_of_print_page.map(()=>{
            return(
                <>
                {(paymentData.map((pData,index)=>{
                    return(
                        <div style={{position:'relative'}}>
                            <table style={{pageBreakAfter:'always'}}>
                            <Watermark />
                            {(pData.bill_message!==undefined && pData.bill_message!=='') &&
                                <tr>
                                    <th  colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?3:(props.is_yearly_package===true)?7:5} style={{textAlign:'center'}}>{pData.bill_message}</th>
                                </tr>
                            }
                            <PrintHeader header_data={pData.school_info} colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?3:(props.is_yearly_package===true)?6:4}/>
                            {(props.is_demand_bill!==undefined && props.is_demand_bill===true) &&
                                <tr>
                                    <th  colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?3:(props.is_yearly_package===true)?7:5} style={{textAlign:'center'}}>DEMAND BILL</th>
                                </tr>
                            }
                            <tr>
                                <th style={{textAlign:'left',borderRight:'none'}} colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?2:(props.is_yearly_package===true)?5:3}>
                                <h4 style={{fontSize:'20px',fontWeight:'bold'}}>
                                {/*  {(pData.contact_info && pData.contact_info.student_sequence) &&
                                        <span>{pData.contact_info.student_sequence} / </span>
                                    } */}
                                    {(pData.contact_info && pData.contact_info.staff_sequence) &&
                                        <span>{pData.contact_info.staff_sequence} / </span>
                                    }
                                    <span>
                                        {(pData.contact_info && pData.contact_info.family_group_lbl)?pData.contact_info.family_group_lbl:pData.contact_name}
                                    </span>
                                    </h4>
                                    <p style={{margin:'0px 0px 5px 0px',fontSize:'15px'}}>
                                        {(pData.contact_info && pData.contact_info.student_sequence)?pData.contact_info.father_name:''}
                                    </p>
                                    {(pData.contact_info) &&
                                    <>
                                        {(pData.contact_info.roll_number) &&
                                            <h4 style={{fontSize:'15px',textTransform:'uppercase'}}>
                                                {pData.contact_info.roll_number} | {pData.contact_info.class}({pData.contact_info.section})
                                            </h4>
                                        }
                                        
                                        <h4 style={{fontSize:'15px'}}>
                                            {(pData.contact_info.mobile_number!=="") &&
                                                <span>{pData.contact_info.mobile_number}</span>
                                            } 
                                            {(pData.contact_info.mobile_number!=="" && pData.contact_info.alt_mobile_number!=="") &&
                                                <span> | </span>
                                            } 
                                            {(pData.contact_info.alt_mobile_number!=="") &&
                                                <span>{pData.contact_info.alt_mobile_number}</span>
                                            } 
                                        </h4>
                                        <h4 style={{fontSize:'15px'}}>
                                            {(pData.contact_info.locality!=="") &&
                                                <span>{pData.contact_info.locality}</span>
                                            } 
                                            {(pData.contact_info.city!=="" && pData.contact_info.locality!=="") &&
                                                <span>, </span>
                                            } 
                                            {(pData.contact_info.city!=="") &&
                                                <span>{pData.contact_info.city}</span>
                                            } 
                                            {(pData.contact_info.city!=="" && pData.contact_info.state!=="") &&
                                                <span>, </span>
                                            } 
                                            {(pData.contact_info.state!=="") &&
                                                <span>{pData.contact_info.state}</span>
                                            } 
                                            {(pData.contact_info.state!=="" && pData.contact_info.pincode!=="") &&
                                                <span>, </span>
                                            } 
                                                {(pData.contact_info.pincode!=="") &&
                                                <span>{pData.contact_info.pincode}</span>
                                            } 
                                        </h4>
                                    </>
                                    }
                                </th>
                                <th colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?2:(props.is_yearly_package===true)?5:3} style={{textAlign:'right',borderLeft:'none'}}>
                                    <h4 style={{fontSize:'20px',fontWeight:'bold'}}>{pData.payment_sequence}</h4>
                                    <h4 style={{fontSize:'15px'}}>{pData.payment_date_display}</h4>
                                </th>
                            </tr>
                            <tbody>
                                <tr>
                                <th style={{textAlign:'left',backgroundColor:'rgb(251 251 251)'}}  colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?3:(props.is_yearly_package===true)?6:4}>PARTICULARS LIST</th>
                                </tr>            
                                <tr>
                                <th style={{textAlign:'left',width:'90px'}}>SNO</th>
                            
                                <th style={{textAlign:'left'}}>FEE TYPE</th>
                                {(props.is_demand_bill===undefined || props.is_demand_bill===false) &&
                                    <th style={{textAlign:'left'}}>REFERENCE</th>
                                }
                                {(props.is_yearly_package===true) &&
                                    <>
                                    <th style={{textAlign:'right'}}>PRICE</th>
                                    <th style={{textAlign:'right'}}>QUANTITY</th>
                                    </>
                                }
                                {(props.is_demand_bill===undefined || props.is_demand_bill===false) &&
                                    <th style={{textAlign:'right'}}>FEE PAID</th>
                                }
                                
                                {(props.is_demand_bill!==undefined && props.is_demand_bill===true) &&
                                    <th style={{textAlign:'right'}}>FEE</th>
                                }
                                </tr>            
                                            {pData.list_item && pData.list_item.map((value,index)=>{
                                                if(value.amount>0){
                                                    item_index = item_index + 1;
                                                  }
                                            return (
                                                <>
                                                {(value.amount>0) &&
                                            <tr>
                                                <td style={{textAlign:'left'}}>
                                                <span>{item_index}</span>
                                                </td>
                                                {(props.is_yearly_package===true) &&
                                                <td style={{textAlign:'left',textTransform:'capitalize',whiteSpace: 'nowrap'}}>
                                                {value.particular_name}
                                                </td>
                                                }
                                                {(props.is_yearly_package===false || props.is_demand_bill===true) &&
                                                <td style={{textAlign:'left',textTransform:'capitalize',whiteSpace: 'nowrap'}}>
                                                    {(parseInt(value.particulars)===3)&&
                                                        <>
                                                            {(parseFloat(value.amount)>0)?value.particulars_name:'Advance Payment'}
                                                        </>
                                                    }
                                                    {(parseInt(value.particulars)!==3)&&
                                                    <>
                                                       {value.particulars_name} ({value.fee_month})
                                                    </>
                                                    }
                                                </td>
                                                }
                                                {(props.is_demand_bill===undefined || props.is_demand_bill===false) &&
                                                <td style={{textAlign:'left'}}>
                                                    {value.reference}
                                                </td>
                                                }
                                                {(props.is_yearly_package===true) &&
                                                    <>
                                                    <th style={{textAlign:'right'}}>{value.fee}</th>
                                                    <th style={{textAlign:'right'}}>{value.quantity}</th>
                                                    </>
                                                }
                                                <td style={{textAlign:'right'}}>₹ {AppUtils.currency_format(value.amount)}</td>
                                            </tr>
                                            }
                                            </>
                                            )
                                        })}
                            </tbody>
                            <PrintFooter colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?3:(props.is_yearly_package===true)?6:4} type="demand_bill" info={pData.school_info}/>
                            <tfoot>
                                <tr>
                                    <td  style={{textAlign:'justify'}} colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?3:(props.is_yearly_package===true)?6:4}>
                                    {(pData.paid_amount) &&
                                        <>
                                        {(props.is_demand_bill!==true) &&
                                            <>
                                                <h4 style={{fontWeight:'bold',fontSize:'16px'}}>Payable Amount : ₹{AppUtils.currency_format(pData.payable_amount)}</h4>
                                                {(parseFloat(pData.discount)>0) &&
                                                <h4 style={{fontWeight:'bold',fontSize:'16px',marginTop:'10px'}}>Discount : ₹{AppUtils.currency_format(pData.discount)}</h4>
                                                }
                                                <h4 style={{fontWeight:'bold',fontSize:'16px',marginTop:'10px'}}>Paid : ₹{AppUtils.currency_format(pData.paid_amount)}/-({pData.payment_mode_txt})</h4>
                                                {(parseFloat(pData.fee_dues)>0) &&
                                                <h4 style={{fontWeight:'bold',fontSize:'16px',marginTop:'10px'}}>Fee Dues : ₹{AppUtils.currency_format(pData.fee_dues)}</h4>
                                                }
                                            {/*  <p style={{fontWeight:'bold',fontSize:'16px',marginTop:'10px'}}>{AppUtils.number_to_words(pData.paid_amount)} Only/-</p> */}
                                            </>
                                        }
                                    {(props.is_demand_bill===true) &&
                                            <>
                                                <h4 style={{fontWeight:'bold',fontSize:'20px',marginTop:'10px'}}>Payable Amount : ₹{AppUtils.currency_format(pData.paid_amount)}/-</h4>
                                                <p style={{fontWeight:'bold',fontSize:'16px',marginTop:'10px'}}>{AppUtils.number_to_words(pData.paid_amount)} Only/-</p>
                                            </>
                                    }
                                        </>
                                    }
                                    </td>
                                </tr>
                            {/*  <tr>
                                    <td colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?3:(props.is_yearly_package===true)?6:4}>
                                        Remarks : {(pData.remarks!=="")?pData.remarks:'-'}
                                    </td>
                                </tr> */}
                                
                            </tfoot>
                            </table>
                        </div>
                    )
                }))}
            
                </>
            )
        }))}
        
    </div>
  );
}
export default PrintDemandBill;