import { CHANGE_MENU,DELETE_ITEM_CONFIRM,CREATE_CLASS,OPEN_ADD_CLASS,UPDATE_CLASS,DELETE_CLASS,SET_CLASS_LIST,CREATE_SECTION,OPEN_ADD_SECTION,UPDATE_SECTION,DELETE_SECTION,OPEN_ADD_SUBJECT,CREATE_SUBJECT,UPDATE_SUBJECT, DELETE_SUBJECT,OPEN_ADD_STAFF_TYPE,CREATE_STAFF_TYPE,UPDATE_STAFF_TYPE, DELETE_STAFF_TYPE,SET_SECTION_LIST,SET_SUBJECT_LIST,SET_STAFF_TYPE_LIST, SET_STUDENTS_LIST, DELETE_STUDENT,IS_STUDENT_EDIT, SET_STUDENT_INFO, IS_STUDENT_VIEW, SET_STAFFS_LIST, DELETE_STAFF,IS_STAFF_EDIT, SET_STAFF_INFO, IS_STAFF_VIEW,CREATE_PAYMENT_TYPE,OPEN_ADD_PAYMENT_TYPE,OPEN_UPDATE_STUDENT_FEE_STRUCTURE,UPDATE_PAYMENT_TYPE,DELETE_PAYMENT_TYPE,SET_PAYMENT_TYPE_LIST, SET_PAYMENTS_LIST, IS_PAYMENT_EDIT, IS_PAYMENT_VIEW, SET_PAYMENT_INFO, DELETE_PAYMENT, UPDATE_EXAM_TYPE, CREATE_EXAM_TYPE, OPEN_ADD_EXAM_TYPE, DELETE_EXAM_TYPE, SET_EXAM_TYPE_LIST, UPDATE_CLASS_ROOM, CREATE_CLASS_ROOM, OPEN_ADD_CLASS_ROOM, DELETE_CLASS_ROOM, SET_CLASS_ROOM_LIST, SET_TRANSPORT_INFO, IS_TRANSPORT_VIEW, IS_TRANSPORT_EDIT, SET_TRANSPORTS_LIST, DELETE_TRANSPORT, SET_ACCESS_CHECK, CHECK_ACCESS, SET_HOLIDAY_LIST, DELETE_HOLIDAY, OPEN_ADD_HOLIDAY, UPDATE_HOLIDAY, CREATE_HOLIDAY, IS_OPEN_CREATE_DEMAND_BILL, SET_SCHOOL_INFO, SET_BULK_DEMAND_BILL_PRINT } from "../actionTypes/actionTypes";
let current_path = window.location.pathname;
let urlArray = current_path.split('/');
let default_main_menu = 'login';
let default_sub_menu = '';
let default_menu_label = 'Login';
let menu_lables = {login:'Login',dashboard:'Dashboard', students:'Students',student:'Students',staffs:'Staffs',reports:'Reports',settings:'Settings',profile:'Profile',class:'Class',section:'Section',class_section_mapping:'Class Section Mapping',subjects:'Subjects',payment_types:'Payment Types',exam_types:'Exam Types',payments:'Payments',class_room:'Class Rooms','holiday-list':'Holiday List','reported-students':'Reported Students',inbox:'Inbox',notices:'Notices'};

let enable_add_btn_list = ['students','staffs','payments','transports'];

let initial_student_info = {};
let initial_staff_info = {};
let initial_payment_info = {};
let initial_transport_info = {};
if(urlArray[1]!==undefined){
  default_main_menu = urlArray[1];
  default_menu_label = menu_lables[default_main_menu];
}
let convert_id = 0;

if(urlArray[2]!==undefined){
  if(enable_add_btn_list.indexOf(default_main_menu)<0){
    default_sub_menu = urlArray[2];
   
    default_menu_label = default_menu_label+' / '+menu_lables[default_sub_menu];
    if(default_sub_menu=='edit' && urlArray[3]==='demand-bill'){
      default_sub_menu = 'edit/demand-bill';
    }else if(default_sub_menu==='add' && urlArray[3]!==undefined && urlArray[3]==='demand-bill'){
      default_sub_menu = 'add/demand-bill';
    }else if(default_sub_menu==='view' && urlArray[3]!==undefined && urlArray[3]==='demand-bill'){
      default_sub_menu = 'view/demand-bill';
    }else if(default_main_menu==='leave_requests'){
      if(default_sub_menu==='student'){
        default_menu_label = 'Leave Requests / Students';
      }else if(default_sub_menu==='staff'){
        default_menu_label = 'Leave Requests / Staffs';
      }
    }
    if(default_sub_menu==='convert'){
      convert_id = urlArray[3];
    }
  }else{
    default_sub_menu = urlArray[2];
  }
  if(default_main_menu==='student' && default_sub_menu==='add'){
    default_menu_label = 'Create Student';
  }else if(default_main_menu==='student' && default_sub_menu==='edit'){
    default_menu_label = 'Update Student';
    let update_id = urlArray[3];
    initial_student_info['student_id'] =  update_id;
    initial_student_info['is_db'] =  true;
  }else if(default_main_menu==='student' && default_sub_menu==='view'){
    default_menu_label = 'Student Profile';
    let update_id = urlArray[3];
    initial_student_info['student_id'] =  update_id;
    initial_student_info['is_db'] =  true;
  }else if(default_main_menu==='staff' && default_sub_menu==='add'){
    default_menu_label = 'Create Staff';
  }else if(default_main_menu==='staff' && default_sub_menu==='edit'){
    default_menu_label = 'Update Staff';
    let update_id = urlArray[3];
    initial_staff_info['staff_id'] =  update_id;
    initial_staff_info['is_db'] =  true;
  }else if(default_main_menu==='staff' && default_sub_menu==='view'){
    default_menu_label = 'Staff Profile';
    let update_id = urlArray[3];
    initial_staff_info['staff_id'] =  update_id;
    initial_staff_info['is_db'] =  true;
  }else if(default_main_menu==='payment' && (default_sub_menu==='add' || default_sub_menu==='add/demand-bill')){
    default_menu_label = 'Create Payment';
    if(default_sub_menu==='add/demand-bill'){
      default_menu_label = 'Create Demand Bill';
    }
  }else if(default_main_menu==='payment' && default_sub_menu==='convert'){
    default_menu_label = 'Covert To Payment';
    default_sub_menu = 'convert/'+convert_id;
    initial_payment_info['id'] =  convert_id;
    initial_payment_info['is_db'] =  true;
  }else if(default_main_menu==='payment' && (default_sub_menu==='edit' || default_sub_menu==='edit/demand-bill')){
    default_menu_label = 'Update Payment';
    let update_id = urlArray[3];
    if(default_sub_menu==='edit/demand-bill'){
      update_id = urlArray[4];
    }
    initial_payment_info['payment_id'] =  update_id;
    initial_payment_info['is_db'] =  true;
  }else if(default_main_menu==='payment' && default_sub_menu==='view'){
    default_menu_label = 'View Payment';
    let update_id = urlArray[3];
    initial_payment_info['payment_id'] =  update_id;
    initial_payment_info['is_db'] =  true;
  }else if(default_main_menu==='payment' && default_sub_menu==='view/demand-bill'){
    default_menu_label = 'View Demand Bill';
    let update_id = urlArray[4];
    initial_payment_info['payment_id'] =  update_id;
    initial_payment_info['is_db'] =  true;
  }else if(default_main_menu==='transport' && default_sub_menu==='add'){
    default_menu_label = 'Create Transport';
  }else if(default_main_menu==='transport' && default_sub_menu==='edit'){
    default_menu_label = 'Update Transport';
    let update_id = urlArray[3];
    initial_transport_info['transport_id'] =  update_id;
    initial_transport_info['is_db'] =  true;
  }else if(default_main_menu==='transport' && default_sub_menu==='view'){
    default_menu_label = 'Transport Profile';
    let update_id = urlArray[3];
    initial_transport_info['transport_id'] =  update_id;
    initial_transport_info['is_db'] =  true;
  }
}

if(urlArray[1]!==undefined && urlArray[2]!==undefined && urlArray[1]==='school' && urlArray[2]==='admin'){
  default_main_menu = 'registered-school';
  if(urlArray[3]==='school_view'){
    default_main_menu = 'school_view';
    default_sub_menu = urlArray[4];
  }
  
}
if(urlArray[3]!==undefined && isNaN(urlArray[3])){
  //default_main_menu = urlArray[3];
}
if(default_main_menu==='demand-bill'){
  default_main_menu = 'payment';
}
let login_info = localStorage.getItem('MnzYs4pVGL');
let is_logged_in = false;
let is_show_website = false;
if(default_main_menu===''){
  is_show_website = true;
}
if(login_info){
  is_logged_in = true;
  if(default_main_menu===''){
    default_main_menu = 'dashboard';
    default_menu_label = 'Dashboard';
  }else if(default_main_menu==='login'){
    is_logged_in = false;
    localStorage.removeItem('MnzYs4pVGL');
  }
}
let admin_login_info = localStorage.getItem('YRqQPcMQMz');
let is_admin_logged_in = false;
if(admin_login_info){
  if(urlArray[1]!==undefined && urlArray[2]!==undefined && urlArray[1]==='school' && urlArray[2]==='admin'){
    is_admin_logged_in = true;
    default_main_menu = 'registered-schools';
    default_menu_label = 'Registered Schools';
    if(urlArray[3]==='school_view'){
      default_main_menu = 'school_view';
      default_sub_menu = urlArray[4];
    }
  }
}
let is_enable_add_btn = false;
if(enable_add_btn_list.indexOf(default_main_menu)>=0){
  is_enable_add_btn = true;
}

const initialState = {
  main_menu: default_main_menu,
  sub_menu: default_sub_menu,
  menu_label: default_menu_label,
  is_enable_add_btn: is_enable_add_btn,
  class_list : [],
  payment_type_list : [],
  exam_type_list : [],
  section_list : [],
  holiday_list : [],
  subject_list : [],
  staff_type_list : [],
  students_list : [],
  payments_list : [],
  class_room_list : [],
  transports_list : [],
  student_info : initial_student_info,
  payment_info : initial_payment_info,
  transport_info : initial_transport_info,
  staffs_list : [],
  staff_info : initial_staff_info,
  is_open_add_class : false,
  is_open_add_section : false,
  is_open_add_subject : false,
  is_open_add_staff_type : false,
  is_delete_item : false,
  is_student_edit : false,
  is_student_view : false,
  student_edit_id : 0,
  is_staff_edit : false,
  is_staff_view : false,
  staff_edit_id : 0,
  is_logged_in : is_logged_in,
  is_show_website : is_show_website,
  is_admin_logged_in : is_admin_logged_in,
  states : ['Andaman and Nicobar Islands', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh', 'Chattisgarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Lakshadweep Islands', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Pondicherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'],
  payment_modes : [
                    {key:1, value:'Cash'},
                    {key:2, value:'Online'},
                    {key:3, value:'Card'},
                    /* {key:4, value:'Cheque'} */
                  ]
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_MENU:
      return {
        ...state,
        main_menu: action.state.main_menu,
        sub_menu: action.state.sub_menu,
        menu_label: action.state.menu_label,
        is_enable_add_btn: action.state.is_enable_add_btn,
        is_student_edit: false,
      };
    case CREATE_CLASS:
      let cls_list = state.class_list;
      let new_list = action.state.class_list;
      cls_list.push(new_list);
      return {
        ...state,
        class_list: cls_list,
      };
    case SET_CLASS_LIST:
      let classes = action.state.class_list;
      return {
        ...state,
        class_list: classes,
      };
    case UPDATE_CLASS:
      let up_cls_list = state.class_list;
      let update_id = action.state.update_id;
      let name = action.state.name;
      up_cls_list[update_id].name = name;
      return {
        ...state,
        class_list: up_cls_list,
      };
    case OPEN_ADD_CLASS:
      return {
        ...state,
        is_open_add_class: action.state.is_open_add_class,
      };
    case DELETE_ITEM_CONFIRM:
      return {
        ...state,
        is_delete_item: action.state.is_delete_item,
      };
    case DELETE_CLASS:
      let del_cls_list = state.class_list;
      let id = action.state.id;
      del_cls_list.splice(id,1);
      return {
        ...state,
        class_list: del_cls_list,
      };
      case CREATE_SECTION:
      let sec_list = state.section_list;
      let new_sec_list = action.state.section_list;
      sec_list.push(new_sec_list);
      return {
        ...state,
        section_list: sec_list,
      };
    case UPDATE_SECTION:
      let up_sec_list = state.section_list;
      let update_sec_id = action.state.update_id;
      let sec_name = action.state.name;
      up_sec_list[update_sec_id].name = sec_name;
      return {
        ...state,
        section_list: up_sec_list,
      };
      case SET_SECTION_LIST:
      let sections = action.state.section_list;
      return {
        ...state,
        section_list: sections,
      };
      case SET_SUBJECT_LIST:
      let subjects = action.state.subject_list;
      return {
        ...state,
        subject_list: subjects,
      };
      case SET_STAFF_TYPE_LIST:
        
      let staff_Types = action.state.staff_type_list;
      return {
        ...state,
        staff_type_list: staff_Types,
      };
    case OPEN_ADD_SECTION:
      return {
        ...state,
        is_open_add_section: action.state.is_open_add_section,
      };
    case DELETE_SECTION:
      let del_sec_list = state.section_list;
      let sec_id = action.state.id;
      del_sec_list.splice(sec_id,1);
      return {
        ...state,
        section_list: del_sec_list,
      };
      case CREATE_SUBJECT:
      let sub_list = state.subject_list;
      let new_sub_list = action.state.subject_list;
      sub_list.push(new_sub_list);
      return {
        ...state,
        subject_list: sub_list,
      };
    case UPDATE_SUBJECT:
      let up_sub_list = state.subject_list;
      let update_sub_id = action.state.update_id;
      let sub_name = action.state.name;
      up_sub_list[update_sub_id].name = sub_name;
      return {
        ...state,
        subject_list: up_sub_list,
      };
    case OPEN_ADD_SUBJECT:
      return {
        ...state,
        is_open_add_subject: action.state.is_open_add_subject,
      };
    case DELETE_SUBJECT:
      let del_sub_list = state.subject_list;
      let sub_id = action.state.id;
      del_sub_list.splice(sub_id,1);
      return {
        ...state,
        subject_list: del_sub_list,
      };
      case CREATE_STAFF_TYPE:
      let staff_type_list = state.staff_type_list;
      let new_staff_type_list = action.state.staff_type_list;
      staff_type_list.push(new_staff_type_list);
      return {
        ...state,
        staff_type_list: staff_type_list,
      };
    case UPDATE_STAFF_TYPE:
      let up_staff_type_list = state.staff_type_list;
      let update_staff_id = action.state.update_id;
      let staff_name = action.state.name;
      up_staff_type_list[update_staff_id].name = staff_name;
      return {
        ...state,
        staff_type_list: up_staff_type_list,
      };
    case OPEN_ADD_STAFF_TYPE:
      return {
        ...state,
        is_open_add_staff_type: action.state.is_open_add_staff_type,
      };
    case DELETE_STAFF_TYPE:
      let del_staff_type_list = state.staff_type_list;
      let staff_type_id = action.state.id;
      del_staff_type_list.splice(staff_type_id,1);
      return {
        ...state,
        staff_type_list: del_staff_type_list,
      };
    case DELETE_STUDENT:
      let del_student_list = state.students_list;
      let student_id = action.state.id;
      del_student_list.splice(student_id,1);
      return {
        ...state,
        students_list: del_student_list,
      };
      case SET_STUDENTS_LIST:
        
      let stud_list = action.state.students_list;
      return {
        ...state,
        students_list: stud_list,
      };
      case IS_STUDENT_EDIT:
        let is_st_edit = action.state.is_student_edit;
        return {
          ...state,
          is_student_edit: is_st_edit,
        };
      case IS_STUDENT_VIEW:
        let is_st_view = action.state.is_student_view;
        return {
          ...state,
          is_student_view: is_st_view,
        };
      case SET_STUDENT_INFO:
        let stu_info = action.state.student_info;
        return {
          ...state,
          student_info: stu_info,
        };
        case DELETE_STAFF:
          let del_staff_list = state.staffs_list;
          let staff_id = action.state.id;
          del_staff_list.splice(staff_id,1);
          return {
            ...state,
            staffs_list: del_staff_list,
          };
          case SET_STAFFS_LIST:
            
          let stf_list = action.state.staffs_list;
          return {
            ...state,
            staffs_list: stf_list,
          };
          case IS_STAFF_EDIT:
            let is_stf_edit = action.state.is_staff_edit;
            return {
              ...state,
              is_staff_edit: is_stf_edit,
            };
          case IS_STAFF_VIEW:
            let is_stf_view = action.state.is_staff_view;
            return {
              ...state,
              is_staff_view: is_stf_view,
            };
          case SET_STAFF_INFO:
            let stf_info = action.state.staff_info;
            return {
              ...state,
              staff_info: stf_info,
            };
            case CREATE_PAYMENT_TYPE:
              let payment_type_list = state.payment_type_list;
              let new_payment_type_list = action.state.payment_type_list;
              payment_type_list.push(new_payment_type_list);
              return {
                ...state,
                payment_type_list: payment_type_list,
              };
            case UPDATE_PAYMENT_TYPE:
              let up_payment_type_list = state.payment_type_list;
              let update_payment_id = action.state.update_id;
              let payment_name = action.state.name;
              up_payment_type_list[update_payment_id].name = payment_name;
              return {
                ...state,
                payment_type_list: up_payment_type_list,
              };
            case OPEN_ADD_PAYMENT_TYPE:
              return {
                ...state,
                is_open_add_payment_type: action.state.is_open_add_payment_type,
              };
            case OPEN_UPDATE_STUDENT_FEE_STRUCTURE:
              return {
                ...state,
                is_open_update_student_fee_structure: action.state.is_open_update_student_fee_structure,
              };
            case DELETE_PAYMENT_TYPE:
              let del_payment_type_list = state.payment_type_list;
              let payment_type_id = action.state.id;
              del_payment_type_list.splice(payment_type_id,1);
              return {
                ...state,
                payment_type_list: del_payment_type_list,
              };
            case SET_PAYMENT_TYPE_LIST:
            let ptlist = action.state.payment_type_list;
            return {
              ...state,
              payment_type_list: ptlist,
            };
            case DELETE_PAYMENT:
            let del_payment_list = state.payments_list;
            let payment_id = action.state.id;
            del_payment_list.splice(payment_id,1);
            return {
              ...state,
              payments_list: del_payment_list,
            };
            case SET_PAYMENTS_LIST:
              
            let pay_list = action.state.payments_list;
            return {
              ...state,
              payments_list: pay_list,
            };
            case IS_PAYMENT_EDIT:
              let is_pay_edit = action.state.is_payment_edit;
              return {
                ...state,
                is_payment_edit: is_pay_edit,
              };
            case IS_PAYMENT_VIEW:
              let is_pay_view = action.state.is_payment_view;
              return {
                ...state,
                is_payment_view: is_pay_view,
              };
            case SET_PAYMENT_INFO:
              let pay_info = action.state.payment_info;
              return {
                ...state,
                payment_info: pay_info,
              };
            case SET_BULK_DEMAND_BILL_PRINT:
              let is_db_print = action.state.is_bulk_demand_bill_print;
              return {
                ...state,
                is_bulk_demand_bill_print: is_db_print,
              };
            case CREATE_EXAM_TYPE:
              let exam_type_list = state.exam_type_list;
              let new_exam_type_list = action.state.exam_type_list;
              exam_type_list.push(new_exam_type_list);
              return {
                ...state,
                exam_type_list: exam_type_list,
              };
            case UPDATE_EXAM_TYPE:
              let up_exam_type_list = state.exam_type_list;
              let update_exam_type_id = action.state.update_id;
              let exam_type_name = action.state.name;
              up_exam_type_list[update_exam_type_id].name = exam_type_name;
              return {
                ...state,
                exam_type_list: up_exam_type_list,
              };
            case OPEN_ADD_EXAM_TYPE:
              return {
                ...state,
                is_open_add_exam_type: action.state.is_open_add_exam_type,
              };
            case DELETE_EXAM_TYPE:
              let del_exam_type_list = state.exam_type_list;
              let exam_type_id = action.state.id;
              del_exam_type_list.splice(exam_type_id,1);
              return {
                ...state,
                exam_type_list: del_exam_type_list,
              };
            case SET_EXAM_TYPE_LIST:
            let extlist = action.state.exam_type_list;
            return {
              ...state,
              exam_type_list: extlist,
            };
            case CREATE_CLASS_ROOM:
              let class_room_list = state.class_room_list;
              let new_class_room_list = action.state.class_room_list;
              class_room_list.push(new_class_room_list);
              return {
                ...state,
                class_room_list: class_room_list,
              };
            case UPDATE_CLASS_ROOM:
              let up_class_room_list = state.class_room_list;
              let update_class_room_id = action.state.update_id;
              let class_room_name = action.state.name;
              up_class_room_list[update_class_room_id].name = class_room_name;
              return {
                ...state,
                class_room_list: up_class_room_list,
              };
            case OPEN_ADD_CLASS_ROOM:
              return {
                ...state,
                is_open_add_class_room: action.state.is_open_add_class_room,
              };
            case DELETE_CLASS_ROOM:
              let del_class_room_list = state.class_room_list;
              let class_room_id = action.state.id;
              del_class_room_list.splice(class_room_id,1);
              return {
                ...state,
                class_room_list: del_class_room_list,
              };
            case SET_CLASS_ROOM_LIST:
            let cls_room_list = action.state.class_room_list;
            return {
              ...state,
              class_room_list: cls_room_list,
            };

            case DELETE_TRANSPORT:
              let del_transport_list = state.transports_list;
              let transport_id = action.state.id;
              del_transport_list.splice(transport_id,1);
              return {
                ...state,
                transports_list: del_transport_list,
              };
              case SET_TRANSPORTS_LIST:
                
              let trans_list = action.state.transports_list;
              return {
                ...state,
                transports_list: trans_list,
              };
              case IS_TRANSPORT_EDIT:
                let is_trans_edit = action.state.is_transport_edit;
                return {
                  ...state,
                  is_transport_edit: is_trans_edit,
                };
              case IS_TRANSPORT_VIEW:
                let is_trans_view = action.state.is_transport_view;
                return {
                  ...state,
                  is_transport_view: is_trans_view,
                };
              case SET_TRANSPORT_INFO:
                let trans_info = action.state.transport_info;
                return {
                  ...state,
                  transport_info: trans_info,
                };
              case SET_SCHOOL_INFO:
                let school_info = action.state.school_info;
                return {
                  ...state,
                  school_info: school_info,
                };
              case SET_ACCESS_CHECK:
                let access_info = action.state.access_info;
                return {
                  ...state,
                  access_info: access_info,
                };
                case CHECK_ACCESS:
                  let acc_key = action.state.key;
                  let is_access = false;
                  if(action.state.access_info && action.state.access_info.access_level && action.state.access_info.access_level.indexOf(acc_key)>=0){
                    is_access = true;
                  }
                  return {
                    ...state,
                    is_access: is_access,
                  };
                  case CREATE_HOLIDAY:
                    let hol_list = state.holiday_list;
                    let new_hol_list = action.state.holiday_list;
                    for (let index = 0; index < new_hol_list.length; index++) {
                      const element = new_hol_list[index];
                      hol_list.push(element);
                    }
                    return {
                      ...state,
                      holiday_list: hol_list,
                    };
                  case UPDATE_HOLIDAY:
                    let up_holiday_list = state.holiday_list;
                    let update_holiday_id = action.state.update_id;
                    let holiday_name = action.state.name;
                    up_holiday_list[update_holiday_id].name = holiday_name;
                    return {
                      ...state,
                      holiday_list: up_holiday_list,
                    };
                  case OPEN_ADD_HOLIDAY:
                    return {
                      ...state,
                      is_open_add_holiday: action.state.is_open_add_holiday,
                    };
                  case IS_OPEN_CREATE_DEMAND_BILL:
                    return {
                      ...state,
                      is_open_create_demand_bill: action.state.is_open_create_demand_bill,
                    };
                  case DELETE_HOLIDAY:
                    let del_holiday_list = state.holiday_list;
                    let holiday_id = action.state.id;
                    del_holiday_list.splice(holiday_id,1);
                    return {
                      ...state,
                      holiday_list: del_holiday_list,
                    };
                  case SET_HOLIDAY_LIST:
                  let hol_day_list = action.state.holiday_list;
                  
                  return {
                    ...state,
                    holiday_list: hol_day_list,
                  };
            default:
              return state;
  }
};