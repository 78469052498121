import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import APP_HTTP from '../../APP_HTTP';

import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
import Watermark from '../WaterMark';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let printDataInitialState = {
    
}

const PrintTransactions = (props)=> {
    let [printData,setprintData] = React.useState(printDataInitialState);
    let total_transaction_amount = 0;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            setTimeout(() => {
                handlePrintTransactions();
            }, 100);
        }
       
    },[props])
   
   const handlePrintTransactions = ()=>{
    if(document.getElementById("printTransactions")){
        var divContents = document.getElementById("printTransactions").innerHTML; 
        
      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
        
        let page_settings='@page{size:"A4";margin:0}';
          a.document.write('<style type="text/css"><style type="text/css">'+page_settings+'table{font-family:verdana, arial, sans-serif;font-size:14px;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:5px;vertical-align:top;border:1px solid #333}h4{margin-top: 0px;margin-bottom: 5px;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
          a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
          a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    
    <div id="printTransactions" style={{display:'none'}}>
      <div style={{position:'relative'}}>
      <Watermark />
      
        <table>
            
              <PrintHeader header_data={props.schoolInfo} colSpan={(props.payment_type==='all')?11:10} content1={'TRANSACTION REPORT'} content2={'REPORT FROM '+props.printData[0].payment_date+' TO '+props.printData[props.printData.length-1].payment_date}/>
             
            
            <tbody>
                    
                <tr>
                    <th style={{textAlign:'left'}}>SNO</th>
                    <th style={{textAlign:'left'}}>PAYMENT</th>
                  
                    <th style={{textAlign:'left'}}>NAME</th>
                    <th style={{textAlign:'left'}}>PARTICULARS</th>
                    <th style={{textAlign:'left'}}>PAYABLE</th>
                    <th style={{textAlign:'left'}}>DISC</th>
                    
                    {(props.paymentModes.map((value,index)=>{
                      return(<th style={{textAlign:'left',textTransform:'uppercase'}}>{value.value}</th>)
                    }))}
                    {(props.payment_type!=='all') &&
                        <th style={{textAlign:'left'}}>AMOUNT</th>
                    }
                    {(props.payment_type==='all') &&
                        <>
                        <th style={{textAlign:'left'}}>EARNING</th>
                        <th style={{textAlign:'left'}}>EXPENSE</th>
                        </>
                    }
                </tr>            
                {props.printData && props.printData.map((row,index)=>{
                    total_transaction_amount = total_transaction_amount + parseFloat(row.paid_amount);
                    return (
                        <tr>
                          <td style={{textAlign:'left'}}>{index+1}</td>
                        <td style={{textAlign:'left'}}>
                            <p style={{margin:'0px'}}>
                            {row.payment_sequence}
                            </p>
                            <p style={{marginTop:'5px'}}>
                            {row.payment_date}
                            </p>
                        </td>
                        <td style={{textAlign:'left',textTransform:'uppercase'}}>
                            <p  style={{margin:'0px'}}>
                              {row.contact_info.name}
                            </p>
                            {(row.contact_info.class!==undefined) &&
                            <p  style={{margin:'0px',marginTop:'5px',marginBottom:'5px', fontSize:'12px'}}>
                              {row.contact_info.roll_number}/{row.contact_info.class}({row.contact_info.section})
                            </p>
                            }
                            {(row.contact_info.father_name!==undefined) &&
                              <p  style={{marginTop:'5px', fontSize:'12px'}}>({row.contact_info.father_name})</p>
                            }
                        </td>
                        
                        <td style={{textAlign:'left'}}>
                          <p style={{margin:'0px'}}>
                            {row.particulars}
                          </p>
                        </td>
                        <td style={{textAlign:'left'}}>
                          <p style={{margin:'0px',lineHeight:'1'}}>
                            {row.payable_amount}
                          </p>
                        </td>
                        <td style={{textAlign:'left'}}>
                          <p style={{margin:'0px',lineHeight:'1'}}>
                            {row.discount}
                          </p>
                        </td>
                        {(props.paymentModes.map((pmval,pmidx)=>{
                          return(
                            <td style={{textAlign:'left',textTransform:'uppercase'}}>
                            {(row.payment_mode_data_display[pmval.id]!==undefined && row.payment_mode_data_display[pmval.id]!=='') &&
                            <>₹{AppUtils.currency_format(row.payment_mode_data_display[pmval.id].value)}</>
                            }
                            {(row.payment_mode_data_display[pmval.id]===undefined || row.payment_mode_data_display[pmval.id]==='') &&
                              <>-</>
                            }
                            </td>
                          )
                        }))}
                      
                        {(props.payment_type!=='all') &&
                               <td component="th" scope="row"  style={{textAlign:'left'}}>
                                ₹{AppUtils.currency_format(row.paid_amount)}
                               </td>
                            }
                            {(props.payment_type==='all') &&
                              <>
                                <td style={{textAlign:'left'}}>
                                  {(row.payment_type_key==='payment_in') &&
                                    <>
                                    ₹{AppUtils.currency_format(row.paid_amount)}
                                    </>
                                  }
                                  {(row.payment_type_key==='payment_out') &&
                                    <>
                                    -
                                    </>
                                  }
                                </td>
                                <td style={{textAlign:'left'}}>
                                {(row.payment_type_key==='payment_out') &&
                                    <>
                                    ₹{AppUtils.currency_format(row.paid_amount)}
                                    </>
                                  }
                                  {(row.payment_type_key==='payment_in') &&
                                    <>
                                    -
                                    </>
                                  }
                                </td>
                              </>
                            }
                        </tr>
                    )
                })}
               
            </tbody>
        </table>
        <table>
          <tbody>
            <tr>
            {(parseFloat(props.total_in_amount)>0) &&
              <td style={{textAlign:'center'}}>
             
        <>
          
            <h2>Earnings</h2>
            <h3>₹{AppUtils.currency_format(props.total_in_amount)}</h3>
            {(props.paymentModes.map((pmval,pmidx)=>{
            return(
              <>
              {(pmval.amount>0) &&
              <> 
                {(pmidx>0) &&
                  <span style={{margin:'0',fontSize:'18px'}}>, </span>
                }
                <span style={{margin:'0',fontSize:'18px'}}>{pmval.value}</span>
                <span style={{margin:'0',fontSize:'18px'}}>:₹{AppUtils.currency_format(pmval.amount)}</span>
              </>
              }
              </>
            )
          }))}
          
        </>
              </td>
        }
        {(parseFloat(props.total_out_amount)>0) &&
              <td style={{textAlign:'center'}}>
              
        <>
        
          <h2>Expenses</h2>
          <h3>₹{AppUtils.currency_format(props.total_out_amount)}</h3>
          {(props.paymentOutModes.map((pmval,pmidx)=>{
            return(
              <>
              {(pmval.amount>0) &&
              <> 
                {(pmidx>0) &&
                  <span style={{margin:'0',fontSize:'18px'}}>, </span>
                }
                <span style={{margin:'0',fontSize:'18px'}}>{pmval.value}</span>
                <span style={{margin:'0',fontSize:'18px'}}>:₹{AppUtils.currency_format(pmval.amount)}</span>
              </>
              }
              </>
            )
          }))}
        
        </>
              </td>
        }
              <td style={{textAlign:'center'}}>
              
              <h2>Profit</h2>
              <h3>₹{AppUtils.currency_format(props.total_transaction_amount)}</h3>
       
              </td>
              <td style={{textAlign:'center'}}>
              
              <h2>Cash In Counter</h2>
              <h3>₹{AppUtils.currency_format(props.cash_in_counter)}</h3>
            
              </td>
            </tr>
          </tbody>
        </table>
       
        </div>
    </div>
  );
}
export default PrintTransactions;