import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdminPanelSettings, CurrencyRupee, Dashboard, Face, FamilyRestroom, Note, NoteAlt, Person, SupervisorAccountTwoTone, Twitter } from '@mui/icons-material';
import './home/css/animate.css';
import './home/css/bootstrap.min.css';
import './home/style.css'
import { Helmet } from 'react-helmet';


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Blog() {
  return (
    <>
    <Helmet>

    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="robots" content="INDEX, FOLLOW" />
    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
    <meta name="googlebot" content="noodp" />
    <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
    <meta name="bingbot" content="noodp" />
    <link rel="alternate" href="https://www.scoogro.com" hreflang="en-US" />
    <link rel="canonical" href="https://www.scoogro.com" />
    <title>No 1 School Management Software In City</title>
    <meta name="description"
      content="App to record students information, staff information, take student attendance from app, automatic submit staff attendace from app, generate demand bill automatically, receive payment, generate pending report, generate attendance report, library management, create certificates, mutiple user management, parent app, teachers app" />
    <meta name="keywords"
      content="attendance app, attendance tracking, mobile attendance, school app, college app, library management, demand bill, receive payment, school management software in biharsharif, school management software in city,staff management, student management,billing" />
    </Helmet>
    <body>
<nav className="navbar navbar-expand-lg">
  <div className="container"> <h1 className="navbar-brand navbar-logo" style={{fontSize:'45px',fontWeight:800}}><span style={{color:'#4285F4'}}>Scoo</span><span style={{color:'#DB4437'}}>Gro</span> </h1><a href="/login" className="about-btn" style={{float:'right'}} rel='nofollow'>Login</a>
   {/*  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <Breadcrumbs </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item"> <a className="nav-link" href="" data-scroll-nav="0">Home</a> </li>
        <li className="nav-item"> <a className="nav-link" href="#" data-scroll-nav="1">About</a> </li>
        <li className="nav-item"> <a className="nav-link" href="#" data-scroll-nav="2">Features</a> </li>
        <li className="nav-item"> <a className="nav-link" href="#" data-scroll-nav="3">Team</a> </li>
        <li className="nav-item"> <a className="nav-link" href="#" data-scroll-nav="4">Testimonials</a> </li>
        <li className="nav-item"> <a className="nav-link" href="#" data-scroll-nav="5">Faq</a> </li>
        <li className="nav-item"> <a className="nav-link" href="#" data-scroll-nav="6">Contact</a> </li>
      </ul>
    </div> */}
  </div>
</nav>
<section className="banner" data-scroll-index='0'>
  <div className="banner-overlay">
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-sm-12">
          <div className="banner-text" style={{textAlign:'center'}}>
            <h2 className="white">No #1 School Management ERP In the City</h2>
            <p className="white" style={{margin:'0px'}}>You Just see your dream, We fulfill with low cost ERP</p>
            <p className="banner-text white" style={{textAlign:'center'}}>At <span style={{color:'#4285F4',fontWeight:800}}>Scoo</span><span style={{color:'#DB4437',fontWeight:800}}>Gro</span>, we strive to offer least pricing while ensuring the highest quality 
standards. Our pricing reflects the value we provide through innovative features, 
reliable performance, and exceptional customer service.</p>
            <ul>
              <li><a href="https://play.google.com/store/apps/details?id=com.scoogro" rel='nofollow'><img src="images/playstore.png" className="wow fadeInUp" data-wow-delay="0.7s"/></a></li>
            </ul>
          </div>
        </div>
        <div className="col-md-4 col-sm-12"> <img src="screenshot1.jpg" className="img-fluid wow fadeInUp"/> </div>
      </div>
    </div>
  </div>
  <span className="svg-wave"> <img className="svg-hero" src="images/applight-wave.svg" /> </span>
</section>
<section className="about section-padding prelative" data-scroll-index='1'>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="sectioner-header text-center">
          <h3>About</h3>
          <span className="line"></span>
          <p>The <span style={{color:'#4285F4',fontWeight:800}}>Scoo</span><span style={{color:'#DB4437',fontWeight:800}}>Gro</span> is a School Management System Software on the web which 
helps in reducing the workload and making it easier for the teachers, parents 
and the administrator. </p>
        
        </div>
        <div className="section-content text-center">
          <div className="row">
            <div className="col-md-4">
              <div className="icon-box wow fadeInUp" data-wow-delay="0.2s"> <i className="fa fa-life-ring" aria-hidden="true"><AdminPanelSettings fontSize='large'/></i>
                <h5>Admin ERP</h5>
                <p>An unique, simple and easy to use Web ERP and the same ERP installable on Desktop for Admin</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="icon-box wow fadeInUp" data-wow-delay="0.4s"> <i className="fa fa-mobile" aria-hidden="true"><FamilyRestroom fontSize='large'/></i>
                <h5>Parent App</h5>
                <p>An android app to access their child day to day activity</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="icon-box wow fadeInUp" data-wow-delay="0.6s"> <i className="fa fa-bolt" aria-hidden="true"><SupervisorAccountTwoTone fontSize='large'/></i>
                <h5>Teachers App</h5>
                <p>An android app for Teachers to perform student attendance, assign homewok etc...</p>
              </div>
            </div>
          </div>
           </div>
      </div>
    </div>
  </div>
</section>
<section className="video-section prelative text-center white">
  <div className="section-padding video-overlay">
    <div className="container">
      
      
      <div className="video-popup">
        <div className="video-src">
          <div className="iframe-src">
           
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="feature section-padding" data-scroll-index='2'>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="sectioner-header text-center">
          <h3>Features</h3>
          <span className="line"></span>
          <p style={{textAlign:'justify'}}>It will help admin to keep the record of students, staffs, attendance, payments etc, and admin can send auto calculated pending payments as 
demand bill to the parents and then convert that demand bill to payment. It also helps the teachers to take student attendance, assign homework to the class using mobile app, and helps the parents to reduce the workload of going to school to view the attendance of his/her child by just using the mobile app. The system will enable a user-friendly environment to which it will help the teachers together with the admin to help key in the information into the system and to reduce redundancies within the system. </p>
        </div>
        <div className="section-content text-center">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="media single-feature wow fadeInUp" data-wow-delay="0.2s">
                <div className="media-body text-right media-right-margin">
                  <h5>Dynamic Dashboard</h5>
                  <p>A quick view of all enrolled students/staff count, current/last month earning & expense, summary of staff/student leave request</p>
                </div>
                <div className="media-right icon-border"> <span className="fa fa-bolt" aria-hidden="true"><Dashboard /></span> </div>
              </div>
              <div className="media single-feature wow fadeInUp" data-wow-delay="0.4s">
                <div className="media-body text-right media-right-margin">
                  <h5>Student Management</h5>
                  <p>Simple and dynamic interface to enroll student with dynamic student list view with pagination</p>
                </div>
                <div className="media-right icon-border"> <span className="fa fa-battery-full" aria-hidden="true"><Face /></span> </div>
              </div>
              <div className="media single-feature wow fadeInUp" data-wow-delay="0.6s">
                <div className="media-body text-right media-right-margin">
                  <h5>Staff Management</h5>
                  <p>Simple and dynamic interface to enroll staff with dynamic enrolled staff list with pagination.</p>
                </div>
                <div className="media-right icon-border"> <span className="fa fa-wifi" aria-hidden="true">
                  <Person/></span> </div>
              </div>
            </div>
            <div className="col-md-4 d-none d-md-block d-lg-block">
              <div className="feature-mobile"> <img src="screenshot1.jpg" className="img-fluid wow fadeInUp"/> </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="media single-feature wow fadeInUp" data-wow-delay="0.2s">
                <div className="media-left icon-border media-right-margin"> <span className="fa fa-check-double" aria-hidden="true"><NoteAlt/> </span> </div>
                <div className="media-body text-left">
                  <h5>Staff Attendance</h5>
                  <p>Administration can take staff attendance in given timeslot for the day, or teachers itselt can mark their availablility through their android app</p>
                </div>
              </div>
              <div className="media single-feature wow fadeInUp" data-wow-delay="0.4s">
                <div className="media-left icon-border media-right-margin"> <span className="fa fa-dollar-sign" aria-hidden="true"><NoteAlt /></span> </div>
                <div className="media-body text-left">
                  <h5>Student Attendance </h5>
                  <p>Teachers can mark student attendance through their android app</p>
                </div>
              </div>
              <div className="media single-feature wow fadeInUp" data-wow-delay="0.6s">
                <div className="media-left icon-border media-right-margin"> <span className="fa fa-hdd" aria-hidden="true"><CurrencyRupee /></span> </div>
                <div className="media-body text-left">
                  <h5>Payments</h5>
                  <p>Accountant can easily generate demand bill in one click, and generated demand bill will be visible in parents android app.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
{/* <section className="team section-padding" data-scroll-index='3'>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="sectioner-header text-center">
          <h3>Our Team</h3>
          <span className="line"></span>
          <p>Sed quis nisi nisi. Proin consectetur porttitor dui sit amet viverra. Fusce sit amet lorem faucibus, vestibulum ante in, pharetra ante.</p>
        </div>
        <div className="section-content text-center">
          <div className="row">
            <div className="col-md-4">
              <div className="team-detail wow bounce" data-wow-delay="0.2s"> <img src="images/user1.jpg" className="img-fluid"/>
                <h4>Nitu Singh</h4>
                <p>Marketing Specialist</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team-detail wow bounce" data-wow-delay="0.4s"> <img src="images/user2.jpg" className="img-fluid"/>
                <h4>Yogesh Singh</h4>
                <p>CEO & Founder</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team-detail wow bounce" data-wow-delay="0.6s"> <img src="images/user3.jpg" className="img-fluid"/>
                <h4>Nisha Sharma</h4>
                <p>Web Developer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="testimonial section-padding" data-scroll-index='4'>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="sectioner-header text-center white">
          <h3>Testimonials</h3>
          <span className="line"></span>
          <p className="white">Sed quis nisi nisi. Proin consectetur porttitor dui sit amet viverra. Fusce sit amet lorem faucibus, vestibulum ante in, pharetra ante.</p>
        </div>
    
      <div className="section-content">
        <div className="row">
          <div className="offset-md-2 col-md-8 col-sm-12">
            <div className="slider">
              <div className="slider-item">
                <div className="test-img"><img src="images/user1.jpg" alt="Placeholder" width="157" height="157" /></div>
                <div className="test-text"><span className="title"><span>John Michal</span> Digital Designer</span> Consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam eratvo lutpat.</div>
              </div>
              <div className="slider-item">
                <div className="test-img"><img src="images/user3.jpg" alt="Placeholder" width="157" height="157" /></div>
                <div className="test-text"><span className="title"><span>Steve Smith</span> App User</span> Euismod tincidunt ut laoreet dolore magna aliquam eratvo lutpat. Ut wisi enim ad minim veniam, quis nostrud v</div>
              </div>
              <div className="slider-item">
                <div className="test-img"><img src="images/user3.jpg" alt="Placeholder" width="157" height="157" /></div>
                <div className="test-text"><span className="title"><span>Gordon Shaw</span> Blogger</span> Consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam eratvo lutpat. Ut wisi enim ad minim veniam, quis nostrud v</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</section>
<section className="faq section-padding prelative" data-scroll-index='5'>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="sectioner-header text-center">
          <h3>Frequently Asked Questions</h3>
          <span className="line"></span>
          <p>Sed quis nisi nisi. Proin consectetur porttitor dui sit amet viverra. Fusce sit amet lorem faucibus, vestibulum ante in, pharetra ante.</p>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.2s">
              <h4>Nam tellus felis, dignissim quis dui ?</h4>
              <p>Suspendisse fermentum placerat enim, at pellentesque augue. Nullam elit est, ultricies et tellus ac, euismod placerat orci. Donec commodo.</p>
            </div>
            <div className="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.2s">
              <h4>Mauris scelerisque, dui non faucibus vulputate ?</h4>
              <p>Sed tempus in neque ac rhoncus. Phasellus vehicula, erat tempor malesuada egestas, mauris tellus malesuada erat, at vestibulum nulla ex et lectus. Nullam elit est, ultricies et tellus ac, euismod placerat orci.</p>
            </div>
            <div className="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.4s">
              <h4>Nullam elit est, ultricies et tellus ac ?</h4>
              <p>Ut vestibulum euismod aliquet. Quisque nec malesuada nibh. Vivamus euismod nunc eu leo faucibus, vel elementum justo posuere. In sed varius nisi. Curabitur id porta ipsum, et vestibulum dui.</p>
            </div>
            <div className="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.4s">
              <h4>Suspendisse fermentum placerat enim, at pellentesque augue elit est ?</h4>
              <p>Vivamus euismod nunc eu leo faucibus, vel elementum justo posuere. In sed varius nisi.</p>
            </div>
            <div className="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.6s">
              <h4>Ut vestibulum euismod aliquet. Quisque nec malesuada nibh ?</h4>
              <p>Suspendisse fermentum placerat enim, at pellentesque augue. Nullam elit est, ultricies et tellus ac, euismod placerat orci. Donec commodo dapibus congue.</p>
            </div>
            <div className="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.6s">
              <h4>Donec commodo dapibus congue ?</h4>
              <p>Nullam elit est, ultricies et tellus ac, euismod placerat orci fermentum placerat enim, at pellentesque augue. Nullam elit est, ultricies et tellus ac, euismod placerat orci. Donec commodo dapibus congue.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}
{/* 
<section className="contact section-padding" data-scroll-index='6'>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="sectioner-header text-center">
          <h3>Contact us</h3>
          <span className="line"></span>
          <p>Sed quis nisi nisi. Proin consectetur porttitor dui sit amet viverra. Fusce sit amet lorem faucibus, vestibulum ante in, pharetra ante.</p>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="contact-info white">
                <div className="contact-item media"> <i className="fa fa-map-marker-alt media-left media-right-margin"></i>
                  <div className="media-body">
                    <p className="text-uppercase">Address</p>
                    <p className="text-uppercase">New Delhi, India</p>
                  </div>
                </div>
                <div className="contact-item media"> <i className="fa fa-mobile media-left media-right-margin"></i>
                  <div className="media-body">
                    <p className="text-uppercase">Phone</p>
                    <p className="text-uppercase"><a className="text-white" href="tel:+15173977100">009900990099</a> </p>
                  </div>
                </div>
                <div className="contact-item media"> <i className="fa fa-envelope media-left media-right-margin"></i>
                  <div className="media-body">
                    <p className="text-uppercase">E-mail</p>
                    <p className="text-uppercase"><a className="text-white" href="mailto:abcdefg@gmail.com">yogeshsingh.now@gmail.com</a> </p>
                  </div>
                </div>
                <div className="contact-item media"> <i className="fa fa-clock media-left media-right-margin"></i>
                  <div className="media-body">
                    <p className="text-uppercase">Working Hours</p>
                    <p className="text-uppercase">Mon-Fri 9.00 AM to 5.00PM.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}
<section className="download section-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="sectioner-header text-center white">
          <h3>Download Our App</h3>
          <span className="line"></span>
          <p className="white">Please click on below link to download our Android App for Teacher and Parents.</p>
        </div>
      </div>
      <div className="col-md-12">
        <div className="section-content text-center">
          <ul>
            <li><a href="https://play.google.com/store/apps/details?id=com.scoogro" rel='nofollow'><img src="images/playstore.png" className="wow fadeInUp" data-wow-delay="0.7s"/></a></li>
          </ul>
        </div>
      </div>
      <div className="col-md-12">
        <div className="section-content text-center">
          <ul>
            <li><a href="/privacy-policy.html" style={{color:'#fff'}} rel='nofollow'>Privacy Policy</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
</section>
{/* 
<footer className="footer-copy">
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12">
        <p>2018 &copy; Applight. Website Designed by <a href="http://w3Template.com" target="_blank" rel="dofollow">W3 Template</a></p>
      </div>
    </div>
  </div>
</footer> */}
</body>
</>
  );
}
