import { Box, Button, ButtonGroup, Dialog, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import { useSelector, useDispatch } from "react-redux";
import { Avatar} from '@mui/material';
import Stack from '@mui/material/Stack';
import BasicDatePicker from '../DatePicker';
import { Check, Close } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AppUtils from '../../AppUtils';
import SuccessLoader from '../SuccessLoader';
import SessionDropdown from '../settings/session_and_year/SessionDropDown';

export default function Students(props) {
    const state = useSelector((state) => state);
  const [isProceedClicked,setProceedAttendence] = React.useState(false);
  const [isShowSubmit,setShowSubmit] = React.useState(false);
  const [isSubmitAttendence,setSubmitAttendence] = React.useState(false);
  const [isSubmittedAttendence,setSubmittedAttendence] = React.useState(false);
  const [isPreviewAttendence,setPreviewAttendence] = React.useState(false);
  const [selected_session,setSession] = React.useState(0);
  const [selected_class,setClass] = React.useState(0);
  const [selected_section,setSection] = React.useState(0);
  const [selected_date,setDate] = React.useState(new Date());
  const [student_list,setStudentListData] = React.useState([]);
  const [is_enabled_timing,setIsEnabledTiming] = React.useState(false);
  const [attendence_time,setAttendenceTime] = React.useState('');
  const [attendence_error,setAttendenceError] = React.useState('');
  const initialized = React.useRef(false);
  const is_college = state.school_info.school_info.is_college;
  React.useEffect(()=>{
    if (!initialized.current) {
        initialized.current = true;
        get_student_attendence_time();
    }

    },[])
   const handleProceedForAttendence = () =>{
        if(selected_class===0 || selected_class==='0' || selected_class==='' || selected_section===0 || selected_section==='0' || selected_section===''){
            return false;
        }
        setProceedAttendence(true);
        setShowSubmit(false);
        APP_HTTP.REQUEST('student/get_students_on_params',{academic_session:selected_session,class:selected_class,section:selected_section,date:selected_date,type:'attendence'},true,true).then((response) => {
            let resp = response.data;
            let stu_list = resp.response;
            setStudentListData(stu_list);
            setProceedAttendence(false);
            let is_show_submit = true;
            for (let index = 0; index < stu_list.length; index++) {
                const element = stu_list[index];
                if(element.attendence===2 || element.attendence==='2'){
                    is_show_submit = false;
                    break;
                }
            }
            setShowSubmit(is_show_submit);
        });
   }
   const handleChangeSession = (id,value) =>{
        setSession(value);
   }
   const handleChangeClass = (id,value) =>{
        setClass(value);
   }
   const handleChangeSection = (id,value) =>{
        setSection(value);
   }
   const handleChangeDate = (id,value) =>{
        setDate(value);
   }

   const manageAttendenceAction = (index,id,value) =>{
       let st_list = [...student_list];
       st_list[index].attendence = value;
       setStudentListData(st_list);
        let is_show_submit = true;
        for (let index = 0; index < st_list.length; index++) {
            const element = st_list[index];
            if(element.attendence===2 || element.attendence==='2'){
                is_show_submit = false;
                break;
            }
        }
        setShowSubmit(is_show_submit);
   }

   const handlePreviewAttendence = () =>{
    setPreviewAttendence(true);
   }
   const handleClosePreview = () =>{
    setPreviewAttendence(false);
   }
   const handleSubmitAttendence = () =>{
    setSubmitAttendence(true);
    APP_HTTP.REQUEST('student/submit_attendence',{date:selected_date, attendence_list:student_list},true,true).then((response) => {
        setSubmittedAttendence(true);
        setTimeout(() => {
            setSubmittedAttendence(false);
            setPreviewAttendence(false);
        }, 750);
        setSubmitAttendence(false);
        let resp = response.data;
        if(resp.success==2){
            setAttendenceError(resp.message);
        }
    });
   }
   
   const get_student_attendence_time = () =>{
    APP_HTTP.REQUEST('settings/get_student_attendence_time',{},true,true).then((response) => {
        let resp = response.data;
        let data = resp.timings;
        if(data.enabled_attendence!==undefined){
            setIsEnabledTiming(data.enabled_attendence);
            setAttendenceTime(data.attendence_time_lbl);
        }
    });
   }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        {(isPreviewAttendence===false) &&
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <BasicDatePicker label="Attendence Date" size="small" handleChangeData={handleChangeDate}/>  
                </Grid>
                {(is_college===true) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <SessionDropdown handleChangeData={handleChangeSession}/>
                </Grid>
                }
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/>     
                </Grid>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                <Sections show="dropdown" handleChangeData={handleChangeSection} defaultValue={selected_section}/>   
                  
                </Grid>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    {(isProceedClicked===false) &&
                        <Button variant="contained" onClick={handleProceedForAttendence}>Proceed</Button>
                    }
                    {(isProceedClicked===true) &&
                        <LoaderButton label="Proceed"/>
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                
            </Grid>
        </Box>
        }
        <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                {(isPreviewAttendence===false) &&
                    <>
                        {(student_list && student_list.length>0) &&
                        <>
                        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }} subheader={
                            <>
                            <ListSubheader component="div" id="nested-list-subheader">
                                STUDENT ATTENDENCE LIST
                                {(is_enabled_timing===false && attendence_time!=="") &&
                                    <Typography variant='span' style={{marginLeft:'10px',color:'red'}}>Attendence Time Closed, You can take attendence between {attendence_time}
                                    </Typography>
                                }
                            </ListSubheader>
                            <Divider />
                            </>
                        }>
                            {student_list.map((row,index) => (
                                <>
                                <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar
                                        alt={row.student_name}
                                        src={row.photo}
                                    />
                                </ListItemAvatar>
                                <ListItemAvatar>
                                    <Stack direction="row" spacing={2}>
                                        <Avatar sx={{ bgcolor: '#1565c0',margin:'0 auto'}}>
                                            {row.roll_number}
                                        </Avatar>
                                    </Stack>
                                </ListItemAvatar>
                                <ListItemText
                                sx={{textAlign:'left',fontSize:'20px'}}
                                primaryTypographyProps={{fontSize: '20px',textTransform:'uppercase'}} 
                                primary={row.student_name}
                                secondary={
                                    <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="h4"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        
                                        {((row.attendence===2 || row.attendence==='2') && row.is_edit_attendence===true) &&
                                        <>
                                        {(AppUtils.has_access(state,'4_attendence_add') || AppUtils.has_access(state,'4_attendence_edit')) &&
                                            <ButtonGroup
                                                disableElevation
                                                variant="contained"
                                                aria-label="Disabled elevation buttons"
                                                >
                                                <Button size="small" color="error" onClick={() => manageAttendenceAction(index,row.student_id,0)}>Absent</Button>
                                                <Button size="small" color="success" onClick={() => manageAttendenceAction(index,row.student_id,1)}>Present</Button>
                                            </ButtonGroup>
                                        }
                                        </>
                                        }
                                        {((row.attendence===2 || row.attendence==='2') && row.is_edit_attendence===false) &&
                                            <Button sx={{marginTop:'-13px'}} size="small">Not Taken</Button>
                                        }
                                        {(row.attendence!==2 && row.attendence!=='2')&&
                                        <>
                                            {(row.attendence===1 || row.attendence==='1') &&
                                                <Check sx={{color:'green'}}/>
                                            }
                                            {(row.attendence===0 || row.attendence==='0') &&
                                                <Close sx={{color:'red'}}/>
                                            }
                                            
                                            {(row.is_edit_attendence===true && AppUtils.has_access(state,'4_attendence_edit')) &&
                                                <Button sx={{marginTop:'-13px'}} size="small" onClick={() => manageAttendenceAction(index,row.student_id,2)}>Change</Button>
                                            }
                                        </>
                                    }
                                    </Typography>
                                    
                                    </React.Fragment>
                                }
                                />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                </>
                            ))}
                            {(isShowSubmit && is_enabled_timing===true) &&
                            <ListItem alignItems="flex-start" sx={{textAlign:'right'}}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="h4"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <Button variant="contained" onClick={handlePreviewAttendence}>Submit</Button>
                                    </Typography>
                                    
                                    </React.Fragment>
                                }
                                />
                            </ListItem>
                            }
                        </List>
                        </>
                        }
                    </>
                }
                {(isPreviewAttendence===true) &&
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Typography variant="h6">ATTENDENCE PREVIEW LIST</Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} spacing={2}>
                        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }} subheader={
                            <>
                            <ListSubheader component="div" id="nested-list-subheader" sx={{display:'flex'}}>
                            <Check sx={{color:'green'}}/> 
                            <Typography> PRESENT LIST </Typography>
                            </ListSubheader>
                            <Divider />
                            </>
                        }>
                        {student_list.map((row,index) => (
                            <>
                            {(row.attendence===1 || row.attendence==='1') &&
                            <>
                            <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar
                                    alt={row.student_name}
                                    src={row.photo}
                                />
                            </ListItemAvatar>
                            <ListItemAvatar>
                                <Stack direction="row" spacing={2}>
                                    <Avatar sx={{ bgcolor: '#1565c0',margin:'0 auto'}}>
                                        {row.roll_number}
                                    </Avatar>
                                </Stack>
                            </ListItemAvatar>
                            <ListItemText
                            sx={{textAlign:'left',fontSize:'20px'}}
                            primaryTypographyProps={{fontSize: '20px',textTransform:'uppercase'}} 
                            primary={row.student_name}
                           
                            />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            </>
                            }
                            </>
                        ))}
                        </List>
                        
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} spacing={2}>
                        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }} subheader={
                            <>
                            <ListSubheader component="div" id="nested-list-subheader" sx={{display:'flex'}}>
                            <Close sx={{color:'red'}}/> 
                            <Typography> ABSENT LIST </Typography>
                            </ListSubheader>
                            <Divider />
                            </>
                        }>
                        {student_list.map((row,index) => (
                            <>
                            {(row.attendence===0 || row.attendence==='0') &&
                            <>
                            <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar
                                    alt={row.student_name}
                                    src={row.photo}
                                />
                            </ListItemAvatar>
                            <ListItemAvatar>
                                <Stack direction="row" spacing={2}>
                                    <Avatar sx={{ bgcolor: '#1565c0',margin:'0 auto'}}>
                                        {row.roll_number}
                                    </Avatar>
                                </Stack>
                            </ListItemAvatar>
                            <ListItemText
                            sx={{textAlign:'left',fontSize:'20px'}}
                            primaryTypographyProps={{fontSize: '20px',textTransform:'uppercase'}} 
                            primary={row.student_name}
                            
                            />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            </>
                            }
                            </>
                        ))}
                        </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2} sx={{textAlign:'right'}}>
                            <Typography>{attendence_error}</Typography>
                            {(isSubmitAttendence===false) &&
                            <>
                            <Button variant="outlined" onClick={handleClosePreview} sx={{marginRight:'10px'}}>Close</Button>
                            {(AppUtils.has_access(state,'4_attendence_add') || AppUtils.has_access(state,'4_attendence_edit')) &&
                            <Button variant="contained" onClick={handleSubmitAttendence}>Submit Attendence</Button>
                                }
                            </>
                            }
                            {(isSubmitAttendence===true) &&
                                <LoaderButton label="Submit Attendence"/>
                            }
                        </Grid>
                    </Grid>
                }
                {(isSubmittedAttendence) &&
                    <SuccessLoader />
                }
            </Box>
        </Box>
    </Box>
  );
}