import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Button, Divider, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import AppPagination from '../AppPagination';
import APP_HTTP from '../../APP_HTTP';
import ActionMenu from './ActionMenu';
import LoaderButton from '../LoaderButton';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SchoolList = () => {
    const [isLoadMore,setIsLoadMore] = React.useState(false);
    const [isAllRecordLoaded,setIsAllRecordLoaded] = React.useState(false);
    const [number_of_page,setNumberOfPage] = React.useState(0);
    const [next_page,setPage] = React.useState(1);
    const [total_records,setTotalRecords] = React.useState(0);
    const [school_list,setSchoolData] = React.useState([]);
    const [is_loading_list,setIsLoadingList] = React.useState(true);
    const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            let current_path = window.location.pathname;
            let numberPattern = /\d+/g;
            let get_page = current_path.match( numberPattern );
            if(get_page===null){
              setIsLoadingList(true);
              handleLoadSchoolList(1)
            }
        }
    },[])
    const handleLoadSchoolList = (page) =>{
      let stList = [...school_list];
      let npage = page+1;
      setPage(npage);
      
      APP_HTTP.REQUEST('school/admin/get_registered_school',{page:page,total_records:total_records},true,false,false,false,true).then((response) => {
          let resp = response.data;
          let stf_list = resp.response;
          if(stf_list.length===0){
            setIsAllRecordLoaded(true);
          }
          for (let index = 0; index < stf_list.length; index++) {
            const element = stf_list[index];
            stList.push(element);
          }
          let tot_record = resp.total_record;
          let no_of_page = resp.number_of_page;
          setTotalRecords(tot_record);
          setNumberOfPage(no_of_page);
          setIsLoadingList(false);
          setIsLoadMore(false);
          setSchoolData(stList);
      });
    }
    const handleLoadMore = () =>{
      setIsLoadMore(true);
      handleLoadSchoolList(next_page);
    }
    const handleViewItem = (id)=>{
      
    }
  
  return (
      <>
          <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            
              <Grid item xs={12} sm={12} md={9} spacing={2}></Grid>
              <Divider />
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TableContainer component={Paper}>
                  <Table  aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        
                        <StyledTableCell>SCHOOL NAME</StyledTableCell>
                        <StyledTableCell>REGISTRATION DATE</StyledTableCell>
                        {/* <StyledTableCell>LAST LOGIN</StyledTableCell> */}
                        <StyledTableCell>STATUS</StyledTableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {(school_list.map((value,index)=>{
                      return(
                        <StyledTableRow>
                        <StyledTableCell sx={{cursor:'pointer'}}>
                          <a href={'/school/admin/school_view/'+value.school}>{value.school_name}</a>
                        </StyledTableCell>
                  
                        <StyledTableCell sx={{cursor:'pointer'}}>
                        {value.reg_date}
                        </StyledTableCell>
                       
                        <StyledTableCell sx={{cursor:'pointer'}}>
                        {(parseInt(value.status)===1)?'Active':'Inactive'}
                        </StyledTableCell>
                      
                    </StyledTableRow>
                      )
                    }))}
                    {(isAllRecordLoaded===false && school_list.length>0) &&
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row" sx={{textAlign:'center'}} colSpan={3}>
                        {(isLoadMore===false) &&
                          <Button variant='outlined' size='small' onClick={handleLoadMore}>Load More</Button>
                        }
                        {(isLoadMore===true) &&
                          <LoaderButton label="Loading..."  variant='outlined' size='small'/>
                        }
                        </StyledTableCell>
                      </StyledTableRow>
                    }
                       
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
             {/*  <Grid item xs={12} sm={12} md={12} spacing={2}>
                <AppPagination number_of_page={number_of_page} handleNavigatePage={handleNavigatePage} url={'/staffs/page'}/>
              </Grid> */}
            </Grid>
            
          </Box>
      </>
  );
}

export default SchoolList;